<template>
    <h1></h1>
</template>

<script>
export default {
    name: 'Dashboard'
}
</script>

<style scoped>

</style>
